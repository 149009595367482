@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Bold Italic';
    src: url('../fonts/GothamPro-BoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Italic';
    src: url('../fonts/GothamPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Light';
    src: url('../fonts/GothamPro-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Medium';
    src: url('../fonts/GothamPro-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Medium Italic';
    src: url('../fonts/GothamPro-MediumItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro Black';
    src: url('../fonts/GothamPro-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
